import React, { useEffect, useState } from 'react'
import Router from 'next/router'
import { useDebouncedCallback } from 'use-debounce'
import { Loading } from '@traefiklabs/faency'

const LoadingBar: React.FC = () => {
  const [isLoading, setLoading] = useState(false)
  const [loadingDone] = useDebouncedCallback(() => {
    setLoading(false)
  }, 500)

  const routeChangeStart = () => {
    setLoading(true)
  }

  const routeChangeEnd = () => {
    loadingDone()
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)
  }, [])

  return isLoading ? (
    <Loading
      sx={{
        position: 'fixed',
        zIndex: 10,
        top: 0,
        left: 0,
        right: 0,
        height: '4px !important',
        background: 'linear-gradient(to right,#e6ae02, #e6ae02,#DA7E10) !important',
      }}
    />
  ) : null
}

export default LoadingBar
