import { useEffect } from 'react'

import Router, { NextRouter } from 'next/router'

const saveScrollPos = (url: string): void => {
  const scrollPos = { x: window.scrollX, y: window.scrollY }
  sessionStorage.setItem(url, JSON.stringify(scrollPos))
}

const restoreScrollPos = (url: string): void => {
  const scrollPos = JSON.parse(sessionStorage.getItem(url))
  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y)
  }
}

const useScrollRestoration = (router: NextRouter): void => {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'scrollRestoration' in window.history && 'sessionStorage' in window) {
      try {
        let shouldScrollRestore = false
        window.history.scrollRestoration = 'manual'
        restoreScrollPos(router.asPath)

        const onBeforeUnload = (event) => {
          saveScrollPos(router.asPath)
          delete event['returnValue']
        }

        const onRouteChangeStart = () => {
          saveScrollPos(router.asPath)
        }

        const onRouteChangeComplete = (url) => {
          if (shouldScrollRestore) {
            shouldScrollRestore = false
            restoreScrollPos(url)
          } else {
            window.scrollTo(0, 0)
          }
        }

        window.addEventListener('beforeunload', onBeforeUnload)
        Router.events.on('routeChangeStart', onRouteChangeStart)
        Router.events.on('routeChangeComplete', onRouteChangeComplete)
        Router.beforePopState(() => {
          shouldScrollRestore = true
          return true
        })

        return () => {
          window.removeEventListener('beforeunload', onBeforeUnload)
          Router.events.off('routeChangeStart', onRouteChangeStart)
          Router.events.off('routeChangeComplete', onRouteChangeComplete)
          Router.beforePopState(() => true)
        }
      } catch (e) {
        console.warn('There was an error when trying to save the scroll position. Cause:', e.message)
      }
    }
  }, [router])
}

export default useScrollRestoration
