import getConfig from 'next/config'
import { useEffect } from 'react'
import { Provider as UIProvider } from '@traefiklabs/faency'
import { SWRConfig } from 'swr'
import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import TagManager from 'react-gtm-module'

import fetcher from '../utils/fetcher'
import { CurrentInstanceProvider } from '../contexts/current-instance'
import { ToastProvider } from '../contexts/toasts'
import LoadingBar from '../components/LoadingBar'
import ToastPool from '../components/ToastPool'
import useScrollRestoration from '../hooks/use-scroll-restoration'
import useAuth from '../hooks/use-auth'

import theme from '../theme'

if (process.env.SENTRY_DSN) {
  const config = getConfig()
  const distDir = config?.serverRuntimeConfig?.rootDir ? `${config.serverRuntimeConfig.rootDir}/.next` : null

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          if (distDir) {
            frame.filename = frame.filename.replace(distDir, 'app:///_next')
          }
          return frame
        },
      }),
    ],
    dsn: process.env.SENTRY_DSN,
  })
}

const { GTM_ID = 'GTM-P26SFBV' } = process.env

const tagManagerArgs = {
  gtmId: GTM_ID,
}

const PilotApp = ({ Component, pageProps, router }) => {
  const getLayout = Component.getLayout || ((page) => page)

  useScrollRestoration(router)

  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      TagManager.initialize(tagManagerArgs)
    }
  }, [user])

  return (
    <SWRConfig
      value={{
        refreshInterval: process.env.POLLING_INTERVAL ? parseInt(process.env.POLLING_INTERVAL, 10) : 300000, // default 5min
        fetcher,
      }}
    >
      <CurrentInstanceProvider>
        <UIProvider theme={theme}>
          <ToastProvider>
            <LoadingBar />
            {getLayout(<Component {...pageProps} />)}
            <ToastPool />
          </ToastProvider>
        </UIProvider>
      </CurrentInstanceProvider>
    </SWRConfig>
  )
}

export default PilotApp
