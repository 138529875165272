import React, { useContext } from 'react'
import { PositionXProps, PositionYProps, getPositionValues } from '../utils/position'
import { ToastContext } from '../contexts/toasts'
import { Flex } from '@traefiklabs/faency'
import Toast from './Toast'

export type ToastPoolProps = {
  positionX?: PositionXProps
  positionY?: PositionYProps
  toastTimeout?: number
}

const ToastPool: React.FC<ToastPoolProps> = ({ positionX = 'center', positionY = 'bottom', toastTimeout = 2000 }) => {
  const { toasts, hideToast } = useContext(ToastContext)

  return (
    <Flex
      paddingX={2}
      margin={positionX === 'center' ? 'auto' : 0}
      sx={{
        ...getPositionValues(positionX, positionY),
        position: 'fixed',
        flexDirection: 'column',
        maxWidth: '420px',
        zIndex: 2,
      }}
      data-testid="toast-pool"
    >
      {toasts?.map((toast) => (
        <Toast key={toast.key} {...toast} dismiss={(): void => hideToast(toast)} timeout={toastTimeout} />
      ))}
    </Flex>
  )
}

export default ToastPool
